<template>
  <el-dialog
      title="取消订单"
      :visible.sync="isOpenModal"
      :close-on-click-modal="false"
      width="800px"
      @closed="closeModal" >
    <div style="width: 90%" >
      <ch-form :render-option="formOption" :model="modalForm" :rules="rules"
               :props="{ labelWidth: '90px' , paddingRight: '0'}" class="mt-10"
               ref="modalForm"
      >
      </ch-form>
      <div style="color: #EB0A0A">需要与顾客联系后才能取消订单</div>
    </div>
    <div slot="footer" class="flex_con">
      <ch-button type="cancel" @click="closeModal">取消</ch-button>
      <ch-button type="ok" :loading="loading" @click="cancelOrder">确定</ch-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    getListFunction: {
      type: Function,
    }
  },
  data() {
    return {
      isOpenModal: false,
      modalForm: {
        cause: ''
      },
      rules: {
        cause: [
            { required: true, message: '请输入取消原因', trigger: 'blur' },
            { type:'string', max: 200, message: '最多输入200个字符', trigger: 'blur' }
        ]
      },
      serviceOrderId: '',
      loading: false
    }
  },
  computed: {
    formOption: function () {
      return [
        {type: 'textarea', label: '取消原因：', prop: 'cause', placeholder: "请输入取消原因",props: {maxlength:200,showWordLimit:true}},
      ]
    }
  },
  methods: {
    openModal(id) {
      this.isOpenModal = true
      this.serviceOrderId = id
    },
    cancelOrder() {
      this.$refs.modalForm.validate((valid) => {
        if (valid) {
          this.loading = true
          this.$curl.post('/hm/order/cancel',{
            serviceOrderId: this.serviceOrderId,
            cause: this.modalForm.cause
          }).then((res)=>{
            if(res.code===200){
              this.$message({
                type: 'success',
                message: '取消成功!'
              })
              this.getListFunction();
              this.closeModal();
            }
          }).finally(()=>{
            this.loading = false
          })

        }
      })
    },
    closeModal() {
      this.isOpenModal = false;
      this.modalForm = {
        cause: ''
      }
      this.loading = false
    }
  }
}
</script>



<style scoped lang="scss">

</style>